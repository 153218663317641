import React, {useEffect, useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Cookies from 'js-cookie';
import {connect} from 'react-redux'; // Import connect
import utils from './utils';
import useAction from './store/actions';
import './styles/index.scss';

import Login from './pages/Login/Login';
import Forget from './pages/Login/Forget';
import Restore from './pages/Login/Restore';
import NotFound from './pages/Error/NotFound';
import Home from './components/Home/Home';
import Config from './components/Config/Config';
import AppLoader from './components/AppLoader/AppLoader';
import AppHeader from './components/AppHeader/AppHeader';
import LeftMenu from './components/LeftMenu/LeftMenu';
import ListView from './components/ListView/ListView';
import Calendar from './components/Calendar/Calendar';
import Detail from './components/Detail/Detail';
import MyProfile from './pages/MyProfile/MyProfile';
import UsersTree from './pages/UsersTree/UsersTree';
import Toast from './components/Toast/Toast';
import Popup from './components/Popups/Popup';
import ErrorPage from './pages/Error/ErrorPage';
import MainPage from './pages/MainPage/MainPage';
import Reports from './components/Reports/Reports';
import Import from './components/Import/Import';
import AppError from './pages/Error/AppError';
import toast, {Toaster} from 'react-hot-toast';

/**
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state) => {
	return state;
};

/**
 * @param {Object} props
 *
 * @return {JSX}
 */
function App(props) {
	const [appError, setAppError] = useState(false);
	const [appErrorMessage, setAppErrorMessage] = useState('');
	const [leftMenuOpen, setLeftMenuOpen] = useState(true);
	const sAction = useAction();

	useEffect(() => {
		sAction.clearModuleData();
	}, [window.location.pathname]);

	useEffect(() => {
		/**
		 * @return {void}
		 */
		const handlePopstate = () => {
			sAction.clearModuleData();
		};

		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
	}, []);

	useEffect(() => {
		if (Cookies.get('offerto' + '_' + utils.getUrlPrefix())) {
			getInitData();
		}
	}, []);

	/**
 	 * @return {JSX}
 	 */
	const renderPopups = () => {
		return props.popups?.map((popup, i) => {
			return <Popup userData={props.user} prefix={`popups/${i}`} popupData={popup} key={popup.id} index={i} />;
		});
	};

	/**
 	 * @return {void}
 	 */
	const getInitData = () => {
		if (window.location.pathname !== '/') {
			sAction.load();
			setAppError(false);
			utils
				.get('getInitData')
				.then((res) => {
					sAction.initData(
						res.translate,
						res.usrInfo,
						res.leftMenu,
						res.usrRight,
						utils.getModuleName(),
						utils.getRecordId(),
						res.currencies,
						res.systemSettings,
						res.systemRoles,
					);
					localStorage.setItem(`offerto_expireTime_${utils.getUrlPrefix()}`, res.expireTime);
					sAction.unload();
				})
				.catch((error) => {
					setAppError(true);
					setAppErrorMessage(error?.message);
					console.error(error);
					sAction.unload();
				});
		}
	};

	const module = sAction.dsGet('view/module');

	if (appError) {
		return <ErrorPage firstMessage={'Oops! The server is not available, please check your internet connection.'} secondMessage={'If your connection is fine please send the message below to the administrator.'} message={appErrorMessage} />;
	}

	if (window.location.pathname === '/') {
		return <MainPage />;
	}

	const errorMessage = utils.translate('LBL_MODULE_NOT_EXISTS');
	const errorInfo = utils.translate('LBL_MODULE_NOT_EXISTS_INFO');

	return (
		<div className="app" id="app">
			{props.config.load ? <AppLoader transparent={false} /> : null}
			{Cookies.get('offerto' + '_' + utils.getUrlPrefix()) ? (
				<>
					<Toaster position="top-right" reverseOrder={false} containerClassName="toaster" />
					{props.toast.length > 0 ? <Toast slices={props.toast} /> : null}
					{props.popups.length > 0 ? renderPopups(props.popups) : null}
					<div className="app__content">
						<LeftMenu setOpen={setLeftMenuOpen} open={leftMenuOpen} modules={props.config.leftMenu} module={module} version={props.config.systemSettings.version} />
						<main className={`app__main ${!leftMenuOpen ? 'app__main-menuClose' : ''}`}>
							{props.config.contentLoad && !props.config.load ? <AppLoader transparent={true} /> : null}
							<AppHeader userData={props.user} />
							<Routes>
								<Route exact path="/:teamName" element={<Home />} />
								<Route exact path="/:teamName/home" element={<Home />} />
								<Route exact path="/:teamName/report" element={<Reports prefix={'view'} reportData={props.view.report} translate={props.config?.translate?.label} />} />
								<Route exact path="/:teamName/login" element={<Home />} />
								<Route exact path="/:teamName/users_tree" element={<UsersTree config={props.config} />} />
								<Route exact path="/:teamName/calendar" element={<Calendar config={props.config} calendarStore={props.view.calendar} />} />
								<Route exact path="/:teamName/config" element={<Config config={props.config} />} />
								<Route exact path="/:teamName/myProfile" element={<MyProfile userData={props.user} />} />
								<Route exact path="/:teamName/list/:module" element={<ListView prefix={'view'} globalModule={module} userData={props.user} config={props.config} />} />
								<Route exact path="/:teamName/detail/:module/:id" element={<Detail viewData={props.view} prefix={'view'} userData={props.user} config={props.config} />} />
								<Route exact path="/:teamName/detail/:module" element={<Detail viewData={props.view} prefix={'view'} userData={props.user} config={props.config} />} />
								<Route exact path="/:teamName/import/:module" element={<Import translate={props.config?.translate?.label} viewData={props.view} />} />
								<Route exact path="*" element={<AppError errorMessage={errorMessage} infoMessage={errorInfo} />} />
							</Routes>
						</main>
					</div>
				</>
			) : (
				<>
					<Routes>
						<Route exact path="/:teamName" element={<Login />} />
						<Route exact path="/:teamName/login" element={<Login />} />
						<Route exact path="/:teamName/forget" element={<Forget />} />
						<Route exact path="/:teamName/restore" element={<Restore />} />
						<Route exact path="/" element={<MainPage />} />
						<Route exact path="*" element={<Login />} />
						{/* <Route exact path="*" element={<ErrorPage link={`${window.location.origin}/${utils.getUrlPrefix()}`} firstMessage={'Page was not found!'} message={appErrorMessage} />} /> */}
					</Routes>
				</>
			)}
		</div>
	);
}

export default connect(mapStateToProps)(App);
