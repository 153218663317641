import React, {useState, useEffect, useCallback} from 'react';
import ActionButtons from '../ActionButtons/ActionButtons';
import ButtonBasic from '../Buttons/ButtonBasic';
import { Tabs, Tab } from '@mui/material';
import utils from '../../utils';
import System from './ConfigPages/System';
import Company from './ConfigPages/Company';
import ExternalSystem from './ConfigPages/ExternalSystem';
import Print from './ConfigPages/Print';
import useAction from '../../store/actions';
import toast from 'react-hot-toast';

/**
 * @export
 *
 * @param {Object} config system configuration
 *
 * @return {JSX}
 */
export default function Config({config}) {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [activeTab, setActiveTab] = useState(0);
	const [state, setState] = useState(config.systemSettings);
	const [emailError, setEmailError] = useState({});
	const [error, setError] = useState({});
	const sAction = useAction();

	useEffect(() => {
		setState(config.systemSettings);
	}, [config.systemSettings]);

	/**
	 * Handles setting value into the config state
	 *
	 * @param {String} name
	 * @param {*} value
	 *
	 * @returns {void}
	 */
	const setValue = (name, value) => {
		if (saveDisabled) {
			setSaveDisabled(false);
		}

		setState({...state, [name]: value});
	};

	const labelSuccess = utils.translate('LBL_SAVE_SUCCESSFUL');
	const labelError = utils.translate('LBL_ERROR');

	const invalidEmailLabel = utils.translate('LBL_INVALID_EMAIL');

	/**
	 * Handles change of the company logo
	 *
	 * @param {String} value
	 *
	 * @returns {void}
	 */
	const validateEmail = (value, field) => {
		let newState = emailError;
		if (!utils.validateEmail(value) && value !== '') {
			newState = ({...emailError, [field]: invalidEmailLabel});
		} else if (value === '') {
			newState = ({...emailError, [field]: ''});
		} else {
			newState = ({...emailError, [field]: ''});
		}

		setEmailError(newState);

		let disableSave = false;
		Object.keys(newState).forEach((key) => {
			const field = newState[key];
			if (field) {
				disableSave = true;
			}
		});

		setSaveDisabled(disableSave);
	};

	useEffect(() => {
		if (state.email) {
			validateEmail(state.email, 'email');
		}
		if (state.invoiceEmail) {
			validateEmail(state.invoiceEmail, 'invoiceEmail');
		}
	}, []);

	const saveSettings = useCallback((disableToast) => {
		const postData = { ...state };
		delete postData.companyLogo;
		utils.post('saveSystemSettings', postData).then((res) => {
			if (res.data !== 0) {
				if (!disableToast) {
					toast.success(labelSuccess);
				}
				sAction.dsSet('config/systemSettings', state);
			} else {
				toast.error(labelError);
			}
		}).catch((error) => {
			console.error(error);
		});
	}, [state, labelSuccess, labelError, sAction]);

	const tabs = {
		0: <Company
			validateEmail={validateEmail}
			emailError={emailError}
			setValue={setValue}
			state={state}
			setSaveDisabled={setSaveDisabled}
		/>,
		1: <System
			setValue={setValue}
			state={state}
			setError={setError}
			error={error}
			setSaveDisabled={setSaveDisabled}
		/>,
		2: <Print
			setValue={setValue}
			state={state}
		/>,
		3: <ExternalSystem
			setValue={setValue}
			state={state}
			saveSettings={saveSettings}
		/>,
	};

	return (
		<>
			<div className="actionBox">
				<ActionButtons>
					<ButtonBasic id={'saveButton'} label={'LBL_SAVE'} key={'save'} icon={'save'} style={'green'} disabled={saveDisabled} onClick={saveSettings} />
				</ActionButtons>
			</div>
			<div className="config">
				<Tabs className="config__tabs" value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
					<Tab iconPosition="start" icon={<span className="iconfas-company"/>} className="config__tab" label={utils.translate('LBL_COMPANY')} />
					<Tab iconPosition="start" icon={<span className="fa-solid fa-gears"/>} className="config__tab" label={utils.translate('LBL_SYSTEM')} />
					<Tab iconPosition="start" icon={<span className="iconfas-print"/>} className="config__tab" label={utils.translate('LBL_PRINT_SETTINGS')} />
					<Tab iconPosition="start" icon={<span className="iconfas-fileExport"/>} className="config__tab" label={utils.translate('LBL_EXTERNAL_SYSTEMS')} />
				</Tabs>
				<div className="config__content">
					{tabs[activeTab]}
				</div>
			</div>
		</>
	);
}
