import React from 'react';
import utils from '../../../../utils';

/**
 * @export
 * @param {Object} def
 *
 * @return {JSX}
 */
export default function LinesGroupHeader({def}) {
	const debugMode = localStorage.getItem('debugMode') ? true : false;

	return (
		<thead className="linesGroupHeader">
			<tr>
				<th className="linesGroupHeader__delete"></th>
				{Object.keys(def)?.map((key) => {
					const column = def[key];
					if (!column.hidden) {
						return <th style={{width: column.width}} key={key}>
							{utils.translate(column.label)}
							{debugMode ? <><span className="debugName"> {column.fieldName}</span><span className="debugType"> {column.fieldType}</span></>  : null}
						</th>;
					}
				})}
			</tr>
		</thead>
	);
}