/**
 * @export
 * @param {Object} state
 * @param {Object} action
 *
 * @return {Object}
 */
export default function initDetail(state, action) {
	const { prefix, fields, buttons, timeline, subpanels } = action.payload;

	if (prefix === 'view') {
		return {
			...state,
			view: {
				...state.view,
				detail: {
					...state.view.detail,
					fields,
					buttons,
					changes: {},
					timeline,
					subpanels: subpanels,
				},
			},
		};
	} else if (prefix.startsWith('popups/')) {
		const popupIndex = parseInt(prefix.split('/')[1], 10);

		if (!isNaN(popupIndex) && popupIndex >= 0 && popupIndex < state.popups.length) {
			const updatedPopups = [...state.popups];
			updatedPopups[popupIndex] = {
				...updatedPopups[popupIndex],
				detail: {
					...updatedPopups[popupIndex].detail,
					fields,
					buttons,
					changes: {},
					timeline,
					subpanels: subpanels,
				},
			};

			return {
				...state,
				popups: updatedPopups,
			};
		}
	}

	return state;
}
