import React, { Suspense, memo, lazy } from 'react';
import { Skeleton } from '@mui/material';

const LinesGroup = lazy(() => import('./LinesGroup'));

/**
 * @param {*} prevProps
 * @param {*} nextProps
 *
 * @return {Boolean}
 */
function arePropsEqual(prevProps, nextProps) {
	return (
		prevProps.group === nextProps.group &&
        prevProps.index === nextProps.index &&
		prevProps.discount === nextProps.discount &&
        prevProps.linesLength === nextProps.linesLength &&
        prevProps.allRows === nextProps.allRows
	);
}

const MemoizedGroup = memo(function MemoizedGroup({
	group,
	index,
	linesDef,
	prefix,
	linesData,
	module,
	linesHaveGroups,
	addRow,
	allRows,
	addNewGroup,
	initCalcLines,
	linesLength,
}) {

	return (
		<Suspense fallback={<Skeleton className="linesGroup__load" variant="rectangular" height={200} />}>
			<LinesGroup
				linesDef={linesDef}
				prefix={prefix}
				index={index}
				group={group}
				linesData={linesData}
				module={module}
				linesHaveGroups={linesHaveGroups}
				addRow={addRow}
				allRows={allRows}
				addNewGroup={addNewGroup}
				initCalcLines={initCalcLines}
				linesLength={linesLength}
			/>
		</Suspense>
	);
}, arePropsEqual);

export default MemoizedGroup;
