import hardRedirect from '../hardRedirect';
import Cookies from 'js-cookie';
import getUrlPrefix from '../getUrlPrefix';
import { convertTimeUnits } from '../exportConfig';

export default function validateUserAction(response, url, clientCookieName) {
	if (!response.data.token && response.data.usrId > -1 && !response.data.stayLoggedIn) {
		if (url !== 'login' && url !== 'forget' && url !== 'restoreValidCode' && url !== 'restore') {
			hardRedirect('/login');
		}
	}

	if (response.data.stayLoggedIn === false) {
		Cookies.remove(clientCookieName);
	} else {
		const expireTime = localStorage.getItem('offerto' + '_expireTime_' + getUrlPrefix());
		if (url !== 'getNotificationCount' && expireTime) {
			Cookies.set('offerto' + '_' + getUrlPrefix(), response.data.token, {expires: convertTimeUnits(expireTime, 'minutes', 'days')});
		}
	}
}
