import React, { useState, useEffect } from 'react';
import useAction from '../../store/actions';

export default function ColumnSort({ sort, columnName, setSort }) {
	const sAction = useAction();
	const [sortState, setSortState] = useState(null);

	const handleClick = () => {
		sAction.contentLoad();
		let currentSort = sort; // Create a new object
		if (currentSort === 0) {
			currentSort = {};
		}
		let sortType = '';
		if (!sortState) {
			sortType = 'DESC';
		} else if (sortState === 'DESC') {
			sortType = 'ASC';
		} else {
			sortType = false;
		}
		const currentLength = Object.keys(currentSort).length;
		if (sortType) {
			currentSort[columnName] = sortType;
		} else {
			delete currentSort[columnName];
		}
		if (currentLength === 1 && !sortType) {
			setSortState(sortType);
			setSort(0);
			sAction.dsSet('view/list/sort', 0);
		} else {
			setSortState(sortType);
			setSort({...sort, ...currentSort});
			sAction.dsSet('view/list/sort', {...sort, ...currentSort});
		}
	};

	useEffect(() => {
		setSortState(sort?.[columnName] || '');
	}, [sort]);

	return (
		<div className="columnSort" onClick={handleClick}>
			<div className={`columnSort__ASC iconfas-triangleUp ${sortState === 'ASC' ? 'columnSort__active' : ''}`}></div>
			<div className={`columnSort__DESC iconfas-triangleDown ${sortState === 'DESC' ? 'columnSort__active' : ''}`}></div>
		</div>
	);
}
